import React from 'react';
import styled from "styled-components";
import {ResponsiveContainer} from "../components/ResponsiveContainer";
import lang from "../lang/lang";
import project1Thumbnail from "../assets/data-manager-screenshot1-thumbnail.png";
import project1Screenshot from "../assets/data-manager-screenshot1.png";
import project2Thumbnail from "../assets/data-panel-screenshot3-thumbnail.png";
import project2Screenshot from "../assets/data-panel-screenshot3.png";
import {Product} from "../components/Product";

const msg = lang.messages();

const Container = styled.div` 
  background-color: ${props => props.theme.palette.white};
`

const Header = styled.h2`
  ${props => props.theme.typography.header2}
  
  align-self: center;
  
  margin-top: ${props => props.theme.sectionTopMargin};
  margin-bottom: 70px;
  
  color: ${props => props.theme.palette.shark};
`

const StyledProduct = styled(Product)`
  margin-bottom: 70px;
`

export const Projects = () => {
  return (
    <Container>
      <ResponsiveContainer>
        <Header>
          {msg.projectsHeader}
        </Header>
        <StyledProduct
          header={msg.projectDataManagerTitle}
          description={msg.projectDataManagerDescription}
          thumbnail={project1Thumbnail}
          image={project1Screenshot}
        />
        <StyledProduct
          header={msg.projectDataPanelTitle}
          description={msg.projectDataPanelDescription}
          thumbnail={project2Thumbnail}
          image={project2Screenshot}
        />
      </ResponsiveContainer>
    </Container>
  )
}