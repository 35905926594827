import React from 'react';
import styled from "styled-components";
import {darken} from "polished";

export const Button = styled.button`
  ${props => props.theme.typography.button}
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  height: ${props => props.theme.sizes.buttonHeight};
  min-width: ${props => props.theme.sizes.buttonMinWidth};
  padding: 0 30px;
  
  color: ${props => props.theme.palette.white};
  background-color: ${props => props.theme.palette.royalBlue};
  &:hover {
    background-color: ${props=>darken(0.1, props.theme.palette.royalBlue)};
  }
  
  border: 0;
  border-radius: 25px;
  
  text-transform: uppercase;
  letter-spacing: 1.5px;
  
  cursor: pointer;

  transition: all 300ms;
  
  &:active {
    outline: 0;
  }
  
  &:focus {
    outline: 0;
  }
`