import React from 'react';
import styled from "styled-components";
import {Button} from "../components/Button";
import {ResponsiveContainer} from "../components/ResponsiveContainer";
import lang from "../lang/lang";
import {darken} from "polished";

const msg = lang.messages();

const Container = styled.div` 
  background-color: ${props => props.theme.palette.white};
`

const Header = styled.h2`
  ${props => props.theme.typography.header2}
  
  align-self: center;
  
  margin-top: ${props => props.theme.sectionTopMargin};
  margin-bottom: 0;
  
  color: ${props => props.theme.palette.shark};
  
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: 70px;
  margin-bottom: 70px;

  background-color: ${props=>props.theme.palette.tango};
  &:hover {
    background-color: ${props=>darken(0.1, props.theme.palette.tango)};
  }

  transition: all 300ms;
`

export const ContactSection = (props: {
  onContactClick?: any,
}) => {
  return (
    <Container>
      <ResponsiveContainer>
        <Header>
          {msg.contactSectionHeader}
        </Header>
        <StyledButton
          onClick={props.onContactClick}
        >
          {msg.contactSectionCTA}
        </StyledButton>
      </ResponsiveContainer>
    </Container>
  )
}