import React from 'react';
import lang from "../lang/lang";
import styled from "styled-components";
import {ResponsiveContainer} from "../components/ResponsiveContainer";
import {Timeline} from "../components/Timeline";

const msg = lang.messages();

const Container = styled.div` 
  background-color: ${props => props.theme.palette.alabaster};
`

const Header = styled.h2`
  ${props => props.theme.typography.header2}
  
  align-self: center;
  
  margin-top: ${props => props.theme.sectionTopMargin};
  margin-bottom: 0;
  
  color: ${props => props.theme.palette.shark};
  
  text-align: center;
`

const StyledTimeline = styled(Timeline)`
  margin-top: 70px;
  margin-bottom: 70px;
  max-width: 600px;
`

export const TimelineSection = () => {
  return (
    <Container>
      <ResponsiveContainer>
        <Header>
          {msg.timelineHeader}
        </Header>
        <StyledTimeline
          elements={[
            {
              header: msg.timelineStep1Header,
              description: msg.timelineStep1Desc,
            },
            {
              header: msg.timelineStep2Header,
              description: msg.timelineStep2Desc,
            },
            {
              header: msg.timelineStep3Header,
              description: msg.timelineStep3Desc,
            },
            {
              header: msg.timelineStep4Header,
              description: msg.timelineStep4Desc,
            },
            {
              header: msg.timelineStep5Header,
              description: msg.timelineStep5Desc,
            }
          ]}
        />
      </ResponsiveContainer>
    </Container>
  )
};