import React from 'react';
import {ScrollingProvider, Section} from "react-scroll-section";
import useWindowDimensions from "../utils/hooks";
import {Introduction} from "./Introduction";
import {AwardSection} from "./AwardSection";
import {Header} from "./Header";
import {Fields} from "./Fields";
import {Projects} from "./Projects";
import {ContactSection} from "./ContactSection";
import {FooterSection} from "./Footer";
import styled from "styled-components";
import {TimelineSection} from "./TimelineSection";
import {MobileNav} from "./MobileNav";
import {TechnologiesSection} from "./TechnologiesSection";

const Container = styled.div`
  /*@media (min-width: ${props => props.theme.breakpoints[0]}) {
    background-color: yellow;
  }
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    background-color: green;
  }
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    background-color: blue;
  }*/
`

export const AllSections = () => {
  const { height, width } = useWindowDimensions();
  let offset = 0;
  if (width <= 768) {
    offset = 0;
  }

  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);

  const handleNavMenuButtonClick = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  }

  const handleNavMenuClose = () => {
    setIsMobileNavOpen(false);
  }

  const onContactClick = (e) => {
    // @ts-ignore
    window.location = 'mailto:contact@anzonia.pl';
    e.preventDefault();
  }

  return (
    <Container>
      <ScrollingProvider offset={offset}>
        <div className="to-push">
          <Header
            onContactClick={onContactClick}
            onNavMenuButtonClick={handleNavMenuButtonClick}
          />
          <Introduction
            onContactClick={onContactClick}
          />
          <Section id={'award'}>
            <AwardSection/>
          </Section>
          <Section id={'fields'}>
            <Fields/>
          </Section>
          <Section id={'timeline'}>
            <TimelineSection/>
          </Section>
          <Section id={'projects'}>
            <Projects/>
          </Section>
          <Section id={'technologies'}>
            <TechnologiesSection />
          </Section>
          <Section id={'contact'}>
            <ContactSection
              onContactClick={onContactClick}
            />
          </Section>
          <FooterSection/>
          <MobileNav
            isOpen={isMobileNavOpen}
            mainContainerSelector=".to-push"
            onClose={handleNavMenuClose}
            onContactClick={onContactClick}
          />
        </div>
      </ScrollingProvider>
    </Container>
  )
}