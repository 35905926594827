import React from 'react'
import styled from "styled-components";

export const ResponsiveContainer = styled.div`
  padding-left: 20px;
  padding-right 20px;
  
  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    width: ${props => props.theme.maxWidth};
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  
  display: flex;
  flex-direction: column;
  align-items: center;
  
  box-sizing: border-box;
`