import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
   
  max-width: 220px;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    max-width: unset;
  }  
  
  border: 2px solid ${props=>props.theme.palette.blackHaze};
  border-radius: 8px;
`

const Icon = styled.img`
  margin-top: 20px;
  width: 60px;
  
  ${Container}:hover & {
    transform: scale(1.2);
  }
  
  transition: all 300ms;
`

const Title = styled.div`
  ${props=>props.theme.typography.header3}
  
  margin-top: 40px;
  
`

const Description = styled.div`
  ${props=>props.theme.typography.subtitle3}
  
  margin: 20px 30px 20px;
  
  text-align: center;
`

export const IconCard = (props: {
  icon: string,
  title: string,
  description: string
}) => {
  return (
    <Container>
      <Icon
        src={props.icon}
        alt=""
      />
      <Title>
        {props.title}
      </Title>
      <Description>
        {props.description}
      </Description>
    </Container>
  )
}