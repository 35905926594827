import React from 'react';
import {AllSections} from "./sections/AllSections";
import {ThemeProvider} from "styled-components";
import {theme} from "./config/theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AllSections/>
    </ThemeProvider>
  );
}

export default App;
