import React from 'react';
import styled from "styled-components";
import {ResponsiveContainer} from "../components/ResponsiveContainer";
import lang from "../lang/lang";
import PytorchLogo from "../assets/technologies/pytorch.svg";
import AwsLogo from "../assets/technologies/aws.svg";
import AzureLogo from "../assets/technologies/azure.svg";
import TensorflowLogo from "../assets/technologies/tensorflow.svg";
import ScikitLogo from "../assets/technologies/scikit_learn.svg";
import ReactLogo from "../assets/technologies/react.svg";

const msg = lang.messages();

const Container = styled.div`
  background-color: ${props => props.theme.palette.alabaster};
`

const Header = styled.h2`
  ${props => props.theme.typography.header2}
  
  align-self: center;
  
  margin-top: ${props => props.theme.sectionTopMargin};
  margin-bottom: 0;
  
  color: ${props => props.theme.palette.shark};
  
  text-align: center;
`

const LogoRootContainer = styled.div`
  margin-top: 70px;
  margin-bottom: 70px;
  
  width: 100%;
  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  
  column-gap: 50px;
  row-gap: 30px;
`

const LogoContainer = styled.a`
  box-sizing: border-box;
  width: 280px;
  height: 210px;
  padding: 30px;
  
  display: flex;
  justify-content: center;

  border-radius: 8px;

  cursor: pointer;

  transition: all 300ms;
  
  &:hover {
    background-color: ${props => props.theme.palette.blackHaze};
  }
  
  &.aws {
    padding: 55px;
  }
`

const Logo = styled.img`
  width: 100%;
`

export const TechnologiesSection = () => {
  return (
    <Container>
      <ResponsiveContainer>
        <Header>
          {msg.technologiesSectionHeader}
        </Header>
        <LogoRootContainer>
          <LogoContainer
            href="https://pytorch.org/"
            title="PyTorch"
          >
            <Logo
              src={PytorchLogo}
              alt="PyTorch"
            />
          </LogoContainer>
          <LogoContainer
            href="https://scikit-learn.org"
            title="scikit-learn"
          >
            <Logo
              src={ScikitLogo}
              alt="Scikit Learn"
            />
          </LogoContainer>
          <LogoContainer
            href="https://www.tensorflow.org/"
            title="TensorFlow"
          >
            <Logo
              src={TensorflowLogo}
              alt="Tensorflow"
            />
          </LogoContainer>
          <LogoContainer
            className="aws"
            href="https://aws.amazon.com/"
            title="Amazon Web Services"
          >
            <Logo
              src={AwsLogo}
              alt="AWS"
            />
          </LogoContainer>
          <LogoContainer
            className="react"
            href="https://reactjs.org/"
            title="React "
          >
            <Logo
              src={ReactLogo}
              alt="React.js"
            />
          </LogoContainer>
          <LogoContainer
            href="https://azure.microsoft.com/"
            title="Microsoft Azure"
          >
            <Logo
              src={AzureLogo}
              alt="Microsoft Azure"
            />
          </LogoContainer>
        </LogoRootContainer>
      </ResponsiveContainer>
    </Container>
  )
}