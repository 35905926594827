import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import ImageViewer from 'react-simple-image-viewer';
import './Product.css';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    flex-direction: column;
    align-items: center;
  }
  
  align-self: stretch;
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0;
`

const ImageContainer = styled.div`
  flex: 1 1 0;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    flex: 1;
  }
  
  margin-left: 0px;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    margin-top: 40px;
  }
  
  border: 2px solid ${props=>props.theme.palette.blackHaze};
  border-radius: 8px;
  
  overflow: hidden;

  &:hover  {
    transform: scale(1.05);
  }

  transition: all 300ms;
`
const Image = styled.img`
  max-width: 100%;
  
  cursor: pointer;
`

const Header = styled.div`
  ${props=>props.theme.typography.header3}
  
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    text-align: center;
  }
`

const Description = styled.div`
  ${props=>props.theme.typography.subtitle3}
  
  margin-top: 20px;
  width: 300px;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    width: unset;
  }
  
  white-space: pre-wrap;
`

export const Product = (props: {
  header: string,
  description: string | JSX.Element,
  thumbnail: string,
  image: string,
  className?: string
}) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [
    props.image
  ]

  const openImageViewer = () => {
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  return (
    <Container className={props.className}>
      <Text>
        <Header>
          {props.header}
        </Header>
        <Description>
          {props.description}
        </Description>
      </Text>
      <ImageContainer>
        <Image
          src={props.thumbnail}
          alt=""
          onClick={openImageViewer}
        />
      </ImageContainer>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={0}
          onClose={ closeImageViewer }
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.8)"
          }}
        />
      )}
    </Container>
  )
}