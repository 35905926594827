import React from 'react';
import styled from "styled-components";
import {ResponsiveContainer} from "../components/ResponsiveContainer";
import lang from "../lang/lang";

const msg = lang.messages();

const Container = styled.footer`
  background-color: ${props => props.theme.palette.shark};
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    flex-direction: column;
    align-items: center;
  }    
  
  margin: 45px 0;
`

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {   
    align-items: center;
    text-align: center;
  }  
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {   
    align-items: center;
    
    margin-top: 20px;
  }  
`

const Text = styled.span`
  ${props => props.theme.typography.subtitle3}
  
  color: ${props => props.theme.palette.doveGray};
`

const Link = styled.a`
  ${props => props.theme.typography.subtitle3}
  
  color: ${props => props.theme.palette.doveGray};
  text-decoration: none;
  
  &:focus {
    cursor: pointer;
    outline: none;
  }
  
  &:hover {
    color: ${props => props.theme.palette.blackHaze};
  }

  transition: all 300ms;
`

export const FooterSection = () => {
  return (
    <Container
    >
      <ResponsiveContainer>
        <TextContainer>
          <Left>
            <span>
              <Text>
                {msg.footerInfo1}
              </Text>
              <Link
                href={'https://anzonia.pl/Anzonia-company-information.pdf'}
              >
                {msg.footerInfoLink}
              </Link>
              <Text>
                .
              </Text>
            </span>
          </Left>
          <Right>
            <Text>
              {msg.footerContact}
            </Text>
            <Link
              href="mailto:contact@anzonia.pl"
            >
              contact@anzonia.pl
            </Link>
          </Right>
        </TextContainer>
      </ResponsiveContainer>
    </Container>
  )
}