const palette = {
  shark: '#222328',
  bigStone: '#181f46',
  brightGray: '#3a3d4b',
  doveGray: '#6B6B6B',
  osloGray: '#8c8f94',
  blackHaze: '#E8E9E9',
  alabaster: '#FAFBFC',
  white: '#ffffff',
  royalBlue: '#3B55E6',
  tango: '#ec7023'
}

const breakpoints = [
  '768px',
  '1024px',
  '1200px',
]

export const theme = {
  palette: {
    ...palette,
  },
  sizes: {
    buttonHeight: '50px',
    buttonMinWidth: '100px'
  },
  maxWidth: '1100px',
  headerHeight: '60px',
  sectionTopMargin: '90px',
  breakpoints: [
    ...breakpoints,
  ],
  typography: {
    button:
      `      
      font-family: Arial;
      font-weight: normal;
      font-size: 14px;
      `,
    header1:
      `
      font-family: Arial;
      font-weight: 500;
      font-size: 65px;
      @media (max-width: ${breakpoints[0]}) {   
        font-size: 45px;
      } 
      
      color: ${palette.shark};
      `,
    header2:
      `
      font-family: Arial;
      font-weight: 500;
      font-size: 35px;
      @media (max-width: ${breakpoints[0]}) {   
        font-size: 30px;
      }       
      
      color: ${palette.shark};
      `,
    header3:
      `
      font-family: Arial;
      font-weight: 500;
      font-size: 20px;
      
      color: ${palette.shark};
      `,
    subtitle1:
      `
      font-family: Arial;
      font-weight: normal;
      font-size: 18px;
      
      color: ${palette.brightGray};
      `,
    subtitle2:
      `
      font-family: Arial;
      font-weight: normal;
      font-size: 18px;
      
      color: ${palette.brightGray};
      `,
    subtitle3:
      `
      font-family: Arial;
      font-weight: 400;
      font-size: 16px;
      
      color: ${palette.osloGray};
      `,
  }
}