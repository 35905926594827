import React from 'react';
import styled from "styled-components";
import logo from '../assets/anzonia-logomark.svg';
import {Button} from "../components/Button";
import lang from "../lang/lang";
import {useScrollSection} from "react-scroll-section/lib";
import {FiMenu} from "react-icons/fi";

const msg = lang.messages();

const StyledHeader = styled.header`
  height: ${props=>props.theme.headerHeight};
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  background-color: ${props => props.theme.palette.white};
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px 0px;
  
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10000;
`

const MainContainer = styled.div`
  padding: 10px 25px;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {   
    padding: 10px 12px 10px 22px;
  }   
    
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  
  @media (min-width: ${props => props.theme.breakpoints[0]}) {   
    justify-content: space-between;
  } 
`

const Logo = styled.img`
  width: 45px;
`

const ButtonGroupLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const ButtonGroupRight = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ButtonContainer = styled.div`
  margin-left: 16px;
  &:first-of-type {
    margin-left: 80px;
  }
  
  display: none;
  
  @media (min-width: ${props => props.theme.breakpoints[0]}) {   
    display: block;
  } 
`

const HeaderButton = styled(Button)`
  padding-left: 12px;
  padding-right: 12px;
  
  color: ${props => props.theme.palette.brightGray};
  background-color: transparent;
  &:hover {
    background-color: ${props=>props.theme.palette.blackHaze};
  }

  border-radius: 8px;
`

const NavMenuButton = styled.button`
  margin: 0;
  margin-right: 0px;
  
  border: 0;
  
  color: ${props => props.theme.palette.shark};
  background: transparent;
  
  cursor: pointer;
  
  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    display: none;
  }
  
  &:active {
    outline: 0;
  }
  
  &:focus {
    outline: 0;
  }
`

export const Header = (props: {
  onContactClick?: any,
  onNavMenuButtonClick?: any,
}) => {
  const awardSection = useScrollSection('award');
  const fieldsSection = useScrollSection('fields');
  const timelineSection = useScrollSection('timeline');
  const projectsSection = useScrollSection('projects');
  const technologiesSection = useScrollSection('technologies');

  const handleLogoClick = () => {
    window.scrollTo(0, 0);
    window.location.reload(true);
  }

  return (
    <StyledHeader>
      <MainContainer>
        <Logo
          src={logo}
          alt=""
          onClick={handleLogoClick}
        />
        <ButtonGroupLeft>
          <ButtonContainer>
            <HeaderButton
              onClick={fieldsSection.onClick}
            >
              {msg.headerIndustries}
            </HeaderButton>
          </ButtonContainer>
          <ButtonContainer>
            <HeaderButton
              onClick={timelineSection.onClick}
            >
              {msg.headerCooperation}
            </HeaderButton>
          </ButtonContainer>
          <ButtonContainer>
            <HeaderButton
              onClick={projectsSection.onClick}
            >
              {msg.headerProjects}
            </HeaderButton>
          </ButtonContainer>
          <ButtonContainer>
            <HeaderButton
              onClick={technologiesSection.onClick}
            >
              {msg.headerTechnologies}
            </HeaderButton>
          </ButtonContainer>
        </ButtonGroupLeft>
        <ButtonGroupRight>
          <ButtonContainer>
            <Button
              onClick={props.onContactClick}
            >
              {msg.headerCTA}
            </Button>
          </ButtonContainer>
          <NavMenuButton
            onClick={props.onNavMenuButtonClick}
          >
            <FiMenu size={32}/>
          </NavMenuButton>
        </ButtonGroupRight>
      </MainContainer>
    </StyledHeader>

  )
}