import React from 'react';
import styled from "styled-components";
import OffCanvas from 'react-aria-offcanvas';
import {useScrollSection} from "react-scroll-section/lib";
import {Button} from "../components/Button";
import lang from "../lang/lang";

const msg = lang.messages();

const StyledMobileNav = styled.div`
`

const SectionButton = styled(Button)`
  ${props => props.theme.typography.subtitle1}
  
  padding: 0 0px;

  background-color: ${props => props.theme.palette.white};
  &:hover {
    background-color: ${props=>props.theme.palette.blackHaze};
  }
`

const Content = styled.div`
  padding-top: 40px;
  height: 100vh;
  box-sizing: border-box; 

  display: flex;
  flex-direction: column;

  background: ${props => props.theme.palette.white};
  
  & ${SectionButton}+${SectionButton} {
    margin-top: 40px;
  }
`

export const MobileNav = (props: {
  mainContainerSelector: string,
  isOpen: boolean,
  onClose?: any,
  onContactClick?: any
}) => {

  const awardSection = useScrollSection('award');
  const fieldsSection = useScrollSection('fields');
  const timelineSection = useScrollSection('timeline');
  const projectsSection = useScrollSection('projects');
  const technologiesSection = useScrollSection('technologies');

  const handleButtonClick = (sectionHook) => {
    sectionHook.onClick();
    if (props.onClose)
      props.onClose();
  }

  return (
    <StyledMobileNav>
      <OffCanvas
        isOpen={props.isOpen}
        lockBodyAfterOpen={false}
        position="right"
        mainContainerSelector={props.mainContainerSelector}
        height="100vh"
        onClose={props.onClose}
      >
        <Content>
          <SectionButton
            onClick={()=>handleButtonClick(fieldsSection)}
          >
            {msg.headerIndustries}
          </SectionButton>
          <SectionButton
            onClick={()=>handleButtonClick(timelineSection)}
          >
            {msg.headerCooperation}
          </SectionButton>
          <SectionButton
            onClick={()=>handleButtonClick(projectsSection)}
          >
            {msg.headerProjects}
          </SectionButton>
          <SectionButton
            onClick={()=>handleButtonClick(technologiesSection)}
          >
            {msg.headerTechnologies}
          </SectionButton>
          <SectionButton
            onClick={props.onContactClick}
          >
            {msg.headerCTA}
          </SectionButton>
        </Content>
      </OffCanvas>
    </StyledMobileNav>
  )
}