import { translate } from 'typed-intl';
import {NoWrap} from "../components/NoWrap";

export default translate( {
  headerCTA: 'Contact',
  headerIndustries: 'Sectors',
  headerProjects: 'Products',
  headerTechnologies: 'Technologies',
  headerCooperation: 'Cooperation',

  introductionHeadline: 'Do you collect a lot of data?',
  introductionSubHeadline: 'We use machine learning and deep learning to help ' +
    'you extract value from data.',
  introductionCTA: 'Ask us a question',

  awardSectionHeadline: (<>2nd prize in a Data Science hackathon organized by <a href="https://kghm.com/en/about-us">KGHM</a>.</>),
  awardSectionSubHeadline: (<>Our project was selected by <a href="https://kghm.com/en/about-us">KGHM</a> experts during the <a href="https://cuvalley.com/">CuValley Hack 2023</a>.</>),
  awardSectionHeadline2: (<>2nd prize in a Data Science hackathon organized by <a href="https://kghm.com/en/about-us">KGHM</a>.</>),
  awardSectionSubHeadline2: (<>Our project "Optimization of a flash smelting furnace" was selected by <a href="https://kghm.com/en/about-us">KGHM</a> experts during the <a href="https://cuvalley.com/en/">CuValley Hack 2021</a>.</>),
  awardSectionHeadline3: <>Cooperation with <a href="https://www.orlen.pl/en/pages/default.aspx">PKN ORLEN</a> during the Space3ac program.</>,
  awardSectionSubHeadline3: <>By working with <a href="https://www.orlen.pl/en/pages/default.aspx">PKN ORLEN</a> we developed a tool for the online monitoring and forecasting of coking in steam cracking furnaces.</>,
  awardSectionHeadline4: <>3rd prize in a hackathon organized by <NoWrap><a href="https://www.pekao.com.pl/en/about-bank.html"> Bank Pekao S.A.</a></NoWrap></>,
  awardSectionSubHeadline4: 'During the Pekao Coders event, we developed an analytic solution based on data from payment terminals.',

  fieldsHeader: 'We work with clients from various areas',
  fieldsTitle1: 'Industry',
  fieldsTitle2: 'Finance',
  fieldsTitle3: 'Retail',
  fieldsDesc1: 'We will help you to prevent failures, stabilize the process, save energy and reduce emissions.',
  fieldsDesc2: 'Let us help you with fraud detection and improving financial decision-making.',
  fieldsDesc3: 'Know the behavior of your clients, plan a better strategy, and increase your sales.',

  timelineHeader: 'How do we work?',
  timelineStep1Header: 'Defining the goal',
  timelineStep1Desc: 'We talk with you about your business and explore together how we can improve it using data analysis.',
  timelineStep2Header: 'Development of a Proof of Concept (POC)',
  timelineStep2Desc: 'We work on your data, focusing on: cleaning, preparation and testing various machine learning algorithms.',
  timelineStep3Header: 'Validation of the POC',
  timelineStep3Desc: 'We present you with a clean and comprehensive report of our work. If you are satisfied with the results, we can move on to product development.',
  timelineStep4Header: 'Implementation of the final product',
  timelineStep4Desc: 'We combine your infrastructure with a scalable machine learning system and adapt to your needs. We can use a cloud service or run the system on your infrastructure.',
  timelineStep5Header: 'Launch and maintenance service',
  timelineStep5Desc: 'After launching the system, we take care of its maintenance.',

  projectsHeader: 'Our products',
  projectDataManagerTitle: 'Data Manager',
  projectDataManagerDescription: (<>A tool for data analysis, visualization, <NoWrap>and modeling.</NoWrap><br/>
    It allows a user to prepare custom pipelines by combining various algorithms in the form of configurable graphic components.
    It comes bundled with methods for data cleaning and preparation, different ways of data visualization, and ready-to-use machine learning and deep learning models.</>),
  projectDataPanelTitle: 'Data Panel',
  projectDataPanelDescription: `Working with PKN ORLEN we've built a tool for monitoring and forecasting of the parameters of steam cracking furnaces.
The app has been run and tested online at PKN ORLEN's oil refinery in Płock, Poland.`,

  contactSectionHeader: 'Do you want to work with us?',
  contactSectionCTA: 'Contact us',

  technologiesSectionHeader: 'Technologies we use',

  footerInfo1: (<>Anzonia Sp. z o.o. <NoWrap>Ul. Domaniewska 47/10,</NoWrap> <NoWrap>02-672 Warsaw,</NoWrap> Poland.<br/> NIP: 7010724536, KRS: 0000698048, </>),
  footerInfoLink: 'Company information',
  footerContact: 'Contact us:',
})