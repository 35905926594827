import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  
  margin-left: 20px;
`

const Circle = styled.div<{
  isLast: boolean
}>`
  width: 15px;
  height: 15px;
  
  border: 2px solid ${props=>props.theme.palette.royalBlue};
  border-radius: 50%;

  ${Container}:first-of-type & {
    background-color: ${props=>props.theme.palette.royalBlue};
  }
  ${Container}:hover & {
    background-color: ${props=>props.theme.palette.royalBlue};
  }

  transition: all 300ms;
`

const Line = styled.div<{
  isLast: boolean
}>`
  flex: 1;
  
  ${props=>props.isLast ? 'display: none;' : ''}
  width: 1px;
  
  background-color: ${props=>props.theme.palette.osloGray};
`

const Header = styled.div`
  ${props=>props.theme.typography.header3}
`

const Description = styled.div`
  ${props=>props.theme.typography.subtitle3}
  
  margin-top: 5px;
  margin-bottom: 40px;
  
  white-space: pre-wrap;
`

export const TimelineElement = (props: {
  header: string,
  description: string,
  isLast: boolean
}) => {
  return (
    <Container>
      <Left>
        <Circle
          isLast={props.isLast}
        />
        <Line
          isLast={props.isLast}
        />
      </Left>
      <Right>
        <Header>
          {props.header}
        </Header>
        <Description>
          {props.description}
        </Description>
      </Right>
    </Container>
  )
}