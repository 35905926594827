import React from 'react';
import styled from "styled-components";
import background from '../assets/background.jpg'
import lang from "../lang/lang";
import {Button} from "../components/Button";
import {ResponsiveContainer} from "../components/ResponsiveContainer";

const msg = lang.messages();

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  margin-top: ${props=>props.theme.headerHeight};
  height: 700px;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    height: 570px;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    background-image: linear-gradient(transparent, white 120%),  url("${background}");
  }
`

const Headline = styled.h1`
  ${props => props.theme.typography.header1}
  
  margin-top: 270px;
  margin-bottom: 0;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    margin-top: 100px;
  }
  
  text-align: center;
`

const SubHeadline = styled.h2`
  ${props => props.theme.typography.subtitle1}
  
  margin-top: 20px;
  margin-bottom: 0;
  
  text-align: center;
  
`

const CTAButton = styled(Button)`
  margin-top: 34px;
`

export const Introduction = (props: {
  onContactClick?: any,
}) => {
  return (
    <Container>
      <ResponsiveContainer>
        <Headline>
          {msg.introductionHeadline}
        </Headline>
        <SubHeadline>
          {msg.introductionSubHeadline}
        </SubHeadline>
        <CTAButton
          onClick={props.onContactClick}
        >
          {msg.introductionCTA}
        </CTAButton>
      </ResponsiveContainer>
    </Container>
  )
};