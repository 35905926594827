import React from 'react';
import styled from "styled-components";
import {ResponsiveContainer} from "../components/ResponsiveContainer";
import {IconCard} from "../components/IconCard";
import icon1 from "../assets/icons8-chemical-plant-2-100.png";
import icon2 from "../assets/icons8-community-grants-100.png";
import icon3 from "../assets/icons8-credit-card-cash-withdrawal-100.png";
import lang from "../lang/lang";

const msg = lang.messages();

const CardContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    flex-direction: column;
    align-items: center;
  }  
  
  margin-top: 70px;
  margin-bottom: 100px;
  
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    & > *:not(:first-child) {
      margin-top: 40px;
    }
  }  
`

const Header = styled.h2`
  ${props => props.theme.typography.header2}
  
  align-self: center;
  
  margin-top: ${props => props.theme.sectionTopMargin};
  margin-bottom: 0;
  
  color: ${props => props.theme.palette.shark};
  
  text-align: center;
`

export const Fields = () => {
  return (
    <ResponsiveContainer>
      <Header>
        {msg.fieldsHeader}
      </Header>
      <CardContainer>
        <IconCard
          icon={icon1}
          title={msg.fieldsTitle1}
          description={msg.fieldsDesc1}
        />
        <IconCard
          icon={icon2}
          title={msg.fieldsTitle2}
          description={msg.fieldsDesc2}
        />
        <IconCard
          icon={icon3}
          title={msg.fieldsTitle3}
          description={msg.fieldsDesc3}
        />
      </CardContainer>
    </ResponsiveContainer>
  )
};