import React from 'react';
import styled from "styled-components";
import {TimelineElement} from "./TimelineElement";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Timeline = (props: {
  elements: { header: string, description: string }[],
  className?: string
}) => {
  return (
    <Container className={props.className}>
      {
        props.elements.map((el, i, array) => (
          <TimelineElement
            header={el.header}
            description={el.description}
            isLast={i === array.length-1}
          />
        ))
      }
    </Container>
  )
}