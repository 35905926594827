import React from 'react';
import styled from "styled-components";
import lang from "../lang/lang";
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import './AwardSection.css';

const msg = lang.messages();

const MainContainer = styled.section`
  background-color: ${props => props.theme.palette.bigStone};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  padding-left: 5px;
  padding-right 5px;
  
  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    width: ${props => props.theme.maxWidth};
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  
  box-sizing: border-box;
`

const AwardContainer = styled.div`
  margin-left: 20px;
  margin-right: auto;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  & a {
    display: inline-block;
    position: relative;
  }
  
  & a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    left: 0;
    bottom: 0;
    background-color: ${props => props.theme.palette.osloGray};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  & a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

const Header = styled.h2`
  ${props => props.theme.typography.header2}
  
  align-self: flex-start;
  
  margin-top: ${props => props.theme.sectionTopMargin};
  margin-bottom: 0px;
  
  color: ${props => props.theme.palette.white};
  
  & a {
    text-decoration: none;
    color: ${props => props.theme.palette.white};
  }
  &:focus a {
    cursor: pointer;
    outline: none;
  }
  & a:after {
    transform: scale(0, 1) translateY(1px);
    background-color: ${props => props.theme.palette.white};
  }
  & a:hover:after {
    transform: scale(1, 1) translateY(1px);
  }
`

const Description = styled.h3`
  ${props => props.theme.typography.subtitle2}
  
  align-self: flex-start;
  
  margin-top: 20px;
  margin-bottom: 85px;
  
  color: ${props => props.theme.palette.osloGray};
  
  & a {
    text-decoration: none;
    color: ${props => props.theme.palette.osloGray};
  }
  &:focus a {
    cursor: pointer;
    outline: none;
  }
  & a:after {
    transform: scale(0, 0.75) translateY(2px);
    background-color: ${props => props.theme.palette.osloGray};
  }
  & a:hover:after {
    transform: scale(1, 0.75) translateY(2px);
    background-color: ${props => props.theme.palette.osloGray};
  }
`
export const AwardSection = () => {
  return (
    <MainContainer>
      <Container>
        <Carousel
          plugins={['arrows']}
        >
          <AwardContainer>
            <Header>
              {msg.awardSectionHeadline}
            </Header>
            <Description>
              {msg.awardSectionSubHeadline}
            </Description>
          </AwardContainer>
          <AwardContainer>
            <Header>
              {msg.awardSectionHeadline2}
            </Header>
            <Description>
              {msg.awardSectionSubHeadline2}
            </Description>
          </AwardContainer>
          <AwardContainer>
            <Header>
              {msg.awardSectionHeadline3}
            </Header>
            <Description>
              {msg.awardSectionSubHeadline3}
            </Description>
          </AwardContainer>
        </Carousel>
      </Container>
    </MainContainer>
  )
};